.erp-app {
  .search-holder {
    padding: 0 0.25rem;
    min-height: 3.25em;
    background: inherit;
    position: sticky;
    top: 0;
    z-index: 1;

    .mat-form-field {
      width: 100%;

      .mat-form-field-wrapper {
        margin: 0;
        padding: 0;

        .mat-form-field-flex {
          margin-top: 0;
        }

        .mat-form-field-subscript-wrapper {
          display: none;
        }

        .mat-form-field-suffix {
          top: 0.25em;

          .mat-icon {
            margin-right: 0;
          }
        }
      }
    }
  }

  &-tablet {
    .mat-select-value {
      padding-right: 3rem;
    }

    .with-order {
      position: relative;
      margin-top: 2rem;
    }
  }
}
