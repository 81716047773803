@import 'variables/color';

.mat-tab-label.mat-tab-label-active {
  opacity: 1;
}

erp-app {
  .mat-tab-group {
    .mat-tab-header {
      margin-bottom: 1rem;
    }

    .mat-tab-label {
      padding: 0 1rem;
      padding-bottom: 1px;
      border-bottom: 1px solid $dark-gray;
      font-weight: 400;

      mat-label {
        color: $charcoal;
      }

      &.mat-tab-label-active {
        border-bottom: 2px solid $blue;
        padding-bottom: 0;
        font-weight: 500;
      }
    }

    &.visible-overflow {
      .mat-tab-body-wrapper,
      .mat-tab-body-wrapper .mat-tab-body {
        overflow: visible !important;
      }
    }
  }

  &.erp-tablet-module {
    .mat-tab-label {
      font-size: 1.5rem;
    }
  }
}
