@import 'variables/color';

.erp-app .erp-tablet-module {
  span,
  mat-label,
  erp-label {
    line-height: normal !important;
  }

  .action-title,
  .action-title mat-label {
    font-size: 1.375rem;
    align-content: center;
    color: $charcoal;
    font-weight: 500;
  }

  .mat-table .mat-cell .mat-button,
  .mat-table a {
    font-size: 1.25rem;
  }

  .gray-bg {
    background-color: whitesmoke;
  }

  .mat-card {
    .hide-btn,
    .show-btn {
      top: 0 !important;
      right: 0 !important;

      a {
        font-size: 1.25rem;
        font-weight: 500;
      }
    }
  }
}
