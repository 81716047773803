@import 'variables/color';

.erp-app {
  .mat-progress-bar {
    &.mat-primary {
      .mat-progress-bar-buffer {
        background-color: $gray;
      }
    }
  }
}
