.erp-app {
  mat-button-toggle-group {
    height: 2rem;
    font-size: 0.875rem;

    mat-button-toggle {
      width: 100%;
    }

    .mat-button-toggle-checked {
      font-weight: 500 !important;
    }

    .mat-button-toggle-label-content {
      padding: 0 1rem;
      line-height: 2rem;
    }
  }

  .erp-tablet-module {
    mat-button-toggle-group {
      height: auto;
      font-size: 1.5rem;
      line-height: 1.75rem;

      .mat-button-toggle-label-content {
        padding: 0.5rem 1.5rem;
      }
    }
  }
}
