$text-alignment-type: (start, center, end, justify);
$ver-alignment-type: (top, middle, bottom, baseline);

.erp-app {
  @each $align in $text-alignment-type {
    .text-#{$align} {
      text-align: $align;
    }
  }

  @each $align in $ver-alignment-type {
    .vert-align-#{$align} {
      vertical-align: $align;
    }
  }
}
