@import 'variables/color';

.erp-app {
  dx-scheduler,
  .dx-scheduler-group-header,
  .dx-overlay-content {
    font: 500 0.875rem/1.75rem Roboto, sans-serif !important;
  }

  .dx-scheduler-group-flex-container,
  .dx-scheduler-group-table,
  .dx-scheduler-sidebar-scrollable,
  .dx-scheduler-sidebar-scrollable::before {
    width: 12.5rem !important;
  }

  .dx-scheduler-header-scrollable,
  .dx-scheduler-date-table-scrollable {
    height: auto !important;
    margin-left: 12.5rem !important;
  }

  .dx-scheduler-timeline-week {
    .dx-scheduler-header-scrollable {
      height: auto !important;
    }

    .dx-scheduler-header-row:not(:first-child) {
      display: none;
    }
  }

  .dx-scheduler-timeline-day {
    .dx-scheduler-header-panel-cell {
      width: 1rem !important;
      border-right: none;
    }

    .dx-scheduler-date-table-cell {
      width: 1rem !important;
    }
  }

  .dx-scheduler-timeline {
    .dx-scheduler-header-panel-cell {
      height: 1.5rem;
      z-index: 100;
      background-color: white;
    }

    .dx-scheduler-date-table-cell,
    .dx-scheduler-header-panel-cell:not(.dx-scheduler-header-panel-week-cell) {
      width: 5.625rem;
      border-right: none;
    }

    .dx-scheduler-date-table,
    .dx-scheduler-date-table-scrollable .dx-scrollable-content,
    .dx-scheduler-group-table,
    .dx-scheduler-sidebar-scrollable .dx-scrollable-content,
    .dx-scheduler-date-table tbody {
      min-height: 0;
      height: auto !important;
    }
  }

  .dx-scheduler-header {
    display: none;
  }

  dx-draggable {
    background-color: #fff;
  }

  .dx-scheduler-work-space {
    padding-top: 0;
    margin-top: 1.5rem;
    border-right: none;
    border-left: none;
  }

  .dx-scheduler-appointment {
    min-width: 90px !important;

    .dx-scheduler-appointment-content {
      text-align: center;
      color: $light-graphite;
      font-weight: 500;
      padding: 2px 5px;

      &.primary {
        background-color: #e6f4fb;
      }

      &.planned {
        background-color: $light-yellow;
      }
    }
  }

  .dx-scheduler-group-header,
  dx-scheduler .dx-scheduler-header:not(.dx-widget) {
    background-color: $blue;
    color: white !important;
    align-items: center !important;
    border: 1px solid $navy-blue !important;
  }

  .dx-scheduler-group-header {
    height: 90px !important;
  }

  .dx-scheduler-appointment-empty .dx-scheduler-appointment-title {
    display: block !important;
    text-align: center;
  }

  .dx-scheduler-header-panel {
    margin-top: 0 !important;
  }

  //// tooltip
  .dx-scheduler-appointment-tooltip-wrapper .dx-popup-content .dx-list-item {
    font-size: 1rem;
    width: auto !important;
    min-width: 200px;

    &.dx-state-active,
    &.dx-state-focused,
    &.dx-state-hover {
      background-color: inherit !important;
      color: inherit !important;
    }

    .dx-list-item-content {
      padding: 0;
    }
  }

  .dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
    max-width: 200px !important;
    height: auto !important;
  }
  .dx-scheduler-appointment-tooltip-wrapper > div.dx-overlay-content {
    max-width: 200px !important;
    height: auto !important;
  }

  .cell {
    height: 100%;
    width: 100%;

    &-disable {
      background-color: $light-grey-second;
    }

    &-maintanence {
      background-color: #ffe9dd;
    }
  }

  .dx-submenu {
    border: none;
  }

  .dx-menu-item {
    color: $blue;
    padding: 0.625rem 1rem;
    font-size: 1rem;
  }

  .dx-context-menu .dx-menu-items-container,
  .dx-menu-base .dx-menu-item-content {
    padding: 0;
  }
}

.dx-overlay-wrapper {
  z-index: 1 !important;
}
