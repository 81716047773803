.erp-app {
  .sticky-header {
    .toolbar {
      left: 20rem;
    }
  }

  .sticky-footer {
    .toolbar {
      left: 20rem;
    }
  }

  mat-sidenav-container.sidenav-collapsed {
    .sticky-header {
      .toolbar {
        left: 2.5rem;
      }
    }

    .sticky-footer {
      .toolbar {
        left: 2.5rem;
      }
    }
  }

  erp-toolbar {
    height: 4.5rem;

    mat-toolbar {
      height: inherit;
      padding: 0 3rem;
    }
  }

  .toolbar-small-text {
    font-size: 0.75rem;
    font-weight: 400;

    .toolbar-small-text {
      font-weight: 500;
    }

    .toolbar-small-label {
      color: $light-graphite;
    }
  }

  .erp-tablet-module {
    erp-toolbar {
      height: 4.75rem;

      &.sticky-header {
        .toolbar {
          left: 3.25rem;
        }
      }

      &.sticky-footer {
        .toolbar {
          left: 3.25rem;
          height: 5rem;
        }
      }

      &.side-footer {
        height: 5rem;
      }

      mat-toolbar {
        padding: 0 3.5rem 0 2rem;

        .back-arrow {
          .mat-icon {
            height: 2.75rem;
            width: 2.75rem;
          }
        }
      }
    }
  }
}
