@use '@angular/material' as mat;
@use 'sass:map';

@mixin erp-sidenav-custom-color($theme) {
  $custom: map.get($theme, custom);
  $primary: map.get($theme, primary);

  .mat-sidenav {
    &[color='light-gray'],
    &[color='light-grey'] {
      background-color: mat.get-color-from-palette($custom, light-gray);
      color: mat.get-color-from-palette(map.get($custom, contrast), light-gray);
    }
    &[color='dark-graphite'] {
      background-color: mat.get-color-from-palette($custom, dark-graphite);
      .mat-toolbar {
        background-color: mat.get-color-from-palette($custom, dark-graphite);
      }
      .mat-nav-list {
        color: mat.get-color-from-palette(map.get($custom, contrast), dark-graphite);
        .active {
          background-color: $navy-blue !important;
        }
      }
    }
  }
}
