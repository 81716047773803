@use '@angular/material' as mat;
@use "sass:map";

@mixin erp-chip-color($theme) {
  $primary: map.get($theme, primary);

  .erp-app {
    mat-chip {
      &[color='transparent'] {
        background-color: transparent;
        color: mat.get-color-from-palette($primary);
      }
    }

    &.erp-app-tablet {
      .mat-chip {
        min-height: 2.5rem;
        padding: 30px;
        font-size: 1.5rem;

        &.mat-standard-chip {
          border-radius: 10px;
        }

        .mat-chip-trailing-icon {
          margin-left: 15px;
        }
      }
    }
  }
}
