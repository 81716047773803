$grid-columns: 12;
$grid-rows: 12;

$grid-phone-columns: 4;
$grid-phone-gap: 0.5rem;
$grid-phone-prefix: -p;

$grid-tablet-columns: 8;
$grid-tablet-gap: 1rem;
$grid-tablet-prefix: -t;
$grid-modal-gap: 1.5rem;
$grid-modal-prefix: -m;
$grid-tablet-info-gap: 0.75rem;
$grid-tablet-info-prefix: -t-i;

$grid-desktop-columns: 12;
$grid-desktop-gap: 2rem;
$grid-desktop-prefix: -d;

$grid-custom-gap: 2.75rem;
$grid-custom-prefix: -c;

.erp-app {
  .inline-grid,
  .layout-inline-grid {
    display: inline-grid;
  }

  .grid,
  .layout-grid {
    display: grid;
    align-content: flex-start;
  }

  .form-grid {
    gap: $grid-phone-gap;
  }

  @mixin grid-gap {
    .grid-gap {
      &-p {
        gap: $grid-phone-gap;
      }

      &-t {
        gap: $grid-tablet-gap;
      }

      &-t-i {
        gap: $grid-tablet-info-gap;
      }

      &-d {
        gap: $grid-desktop-gap;
      }

      &-c {
        gap: $grid-custom-gap;
      }

      &-m {
        gap: $grid-modal-gap;
      }
    }
  }

  @mixin grid-column-gap {
    .grid-column-gap {
      &-p {
        column-gap: $grid-phone-gap;
      }

      &-t {
        column-gap: $grid-tablet-gap;
      }

      &-d {
        column-gap: $grid-desktop-gap;
      }

      &-c {
        column-gap: $grid-custom-gap;
      }

      &-m {
        column-gap: $grid-modal-gap;
      }
    }
  }

  @mixin grid-row-gap {
    .grid-row-gap {
      &-p {
        row-gap: $grid-phone-gap;
      }

      &-t {
        row-gap: $grid-tablet-gap;
      }

      &-d {
        row-gap: $grid-desktop-gap;
      }

      &-c {
        row-gap: $grid-custom-gap;
      }

      &-m {
        row-gap: $grid-modal-gap;
      }

      &-0 {
        row-gap: 0;
      }
    }
  }

  @mixin layout-grid-rows($max) {
    @for $rows from 1 through $max {
      .row-#{$rows} {
        grid-row: span $rows / auto;
      }
    }

    @for $start from 1 through $max {
      @for $end from 1 through $max + 1 {
        @if $start < $end - 1 {
          > .row-#{$start}-#{$end - 1} {
            grid-row: #{$start} / #{$end};
          }
        }
      }
    }
  }

  @mixin layout-grid-columns($max, $prefix: '') {
    @for $cols from 1 through $max {
      > .col#{$prefix}-#{$cols} {
        grid-column: span $cols / auto;
      }

      @if $cols < $max {
        > .col#{$prefix}-#{$cols} + .col#{$prefix}-auto {
          grid-column: auto / span $max - $cols;
        }
      }
    }

    @for $start from 1 through $max {
      @for $end from 1 through $max + 1 {
        @if $start < $end - 1 {
          > .col#{$prefix}-#{$start}-#{$end - 1} {
            grid-column: #{$start} / #{$end};
          }
        }
      }
    }

    > .col#{$prefix}-hide {
      display: none;
    }

    > .col#{$prefix}-full {
      grid-column: 1 / #{$max + 1};
    }
  }

  @supports (display: grid) and (display: inline-grid) {
    .grid,
    .inline-grid,
    .layout-grid,
    .layout-inline-grid {
      grid-template: auto / repeat($grid-columns, 1fr);

      &--auto-first-column {
        grid-template: auto / auto 1fr;
        grid-gap: $grid-phone-gap;
      }

      @include layout-grid-columns($grid-columns);
      @include layout-grid-rows($grid-rows);
    }

    @include grid-gap();
    @include grid-column-gap();
    @include grid-row-gap();

    @media only screen and (max-width: 767px) {
      .layout-grid,
      .layout-inline-grid {
        gap: 0.5rem 0.5rem;
        grid-template: auto / repeat($grid-phone-columns, 1fr);

        @include layout-grid-columns($grid-phone-columns, $grid-phone-prefix);
      }

      .layout-grid-gap {
        gap: $grid-phone-gap $grid-phone-gap;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 1279px) {
      .layout-grid,
      .layout-inline-grid {
        gap: 1rem 1rem;
        grid-template: auto / repeat($grid-tablet-columns, 1fr);

        @include layout-grid-columns($grid-tablet-columns, $grid-tablet-prefix);
      }

      .layout-grid-gap {
        gap: $grid-tablet-gap $grid-tablet-gap;
      }
    }

    @media only screen and (min-width: 1280px) {
      .layout-grid,
      .layout-inline-grid {
        gap: 2rem 2rem;
        grid-template: auto / repeat($grid-desktop-columns, 1fr);

        @include layout-grid-columns($grid-desktop-columns, $grid-desktop-prefix);
      }

      .layout-grid-gap {
        gap: $grid-desktop-gap $grid-desktop-gap;
      }
    }
  }
}
