.erp-app {
  .mat-autocomplete-panel {
    max-width: 32rem;
    width: auto;
  }

  .mat-option {
    height: 2.5rem;

    .mat-option-text {
      line-height: normal;
    }
  }

  .mat-option-empty {
    height: 4rem;
    text-align: center;
  }
}
