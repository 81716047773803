@use '@angular/material' as mat;
@use 'sass:map';

@mixin erp-slide-toggle-custom-color($theme) {
  $custom: map.get($theme, custom);

  .mat-lime.mat-checked {
    .mat-slide-toggle {
      &-bar {
        background-color: mat.get-color-from-palette($custom, lime);
      }

      &-thumb {
        background-color: $whitesmoke;
      }
    }
  }
}
