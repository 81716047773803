@import 'variables/color';

@mixin erp-checkbox-color($theme) {
  $primary: map-get($theme, primary);
}

.erp-app-tablet {
  .mat-mdc-checkbox label {
    font-weight: 400;
    font-size: 1.5rem;
  }
}
