@import 'variables/color';

.erp-app {
  .mat-menu-panel {
    min-height: 0;

    .mat-menu-item {
      font-size: 1rem;
      font-weight: 500;
      color: $blue;
      padding: 0 1.75rem;
      height: 2rem;
      line-height: 2;

      &:disabled {
        opacity: 0.5;
      }
    }

    .mat-menu-content:not(:empty) {
      padding: 0.8rem 0;
    }
  }
}
