@use '@angular/material' as mat;
@use 'sass:map';

@mixin erp-paginator-color($theme) {
  $primary: map.get($theme, primary);
  $custom: map.get($theme, custom);

  .erp-paginator {
    &-btn {
      color: mat.get-color-from-palette($primary);

      &:hover {
        background-color: mat.get-color-from-palette($custom, light-gray);
      }

      &__active {
        color: mat.get-color-from-palette($primary, '500-contrast');
        background-color: mat.get-color-from-palette($primary);

        &:hover {
          background-color: mat.get-color-from-palette($primary, darker);
        }
      }
    }

    &-arrow {
      background-color: mat.get-color-from-palette($primary);

      &:hover {
        background-color: mat.get-color-from-palette($primary, darker);
      }

      &__disabled {
        background-color: mat.get-color-from-palette($custom, light-gray);

        &:hover {
          background-color: mat.get-color-from-palette($custom, light-gray);
        }
      }
    }
  }
}
