@use '@angular/material' as mat;
.erp-app {
  .mat-dialog-container {
    border-radius: 0.125rem;
    padding: 1.5rem;
  }

  .mat-dialog-content {
    max-height: unset;
    margin: 0;
    padding: 0;
  }

  .mat-dialog-actions {
    margin: 0;
    min-height: unset;
    padding: 0;
  }

  .transparent {
    background: transparent;
  }

  .erp-filter-dialog {
    .mat-dialog-container {
      @include mat.elevation(1);

      border-radius: unset;
    }
  }

  .erp-spinner-dialog {
    .mat-dialog-container {
      padding: 0 !important;
      box-shadow: none;
    }
  }

  .erp-side-dialog {
    .mat-dialog-container {
      @include mat.elevation(1);

      border-radius: unset;
      overflow: unset;
      padding: 0;
    }
  }

  .mat-simple-snackbar {
    span {
      display: inline-block;
      width: 100%;
    }
  }

  &.erp-app-tablet,
  .erp-tablet-module {
    .mat-dialog-container {
      padding: 2.5rem;
      max-width: 42.5rem;

      erp-button mat-icon {
        height: 2.5rem;
        width: 2.5rem;
      }

      .dialog-header {
        margin-right: 3rem;
      }

      .mat-dialog-title {
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: 2.5rem;
      }

      .mat-dialog-content {
        font-size: 1.375rem;
        margin-bottom: 3rem;
        line-height: 1.625rem;
      }
    }
    .mat-datepicker-dialog {
      max-width: 100% !important;
    }
    .erp-side-dialog,
    .mat-datepicker-dialog {
      .mat-dialog-container {
        @include mat.elevation(1);

        max-width: inherit;
        border-radius: unset;
        overflow: unset;
        padding: 0;
        .dialog {
          &-title {
            margin: 0;
          }

          &-close {
            cursor: pointer;
            margin-right: -1.5rem;
          }

          &-content {
            padding: 1.5rem 3rem;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
