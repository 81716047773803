@use '@angular/material' as mat;
@use "sass:map";

@mixin erp-calendar-color($theme) {
  $primary: map.get($theme, primary);

  .erp-app {
    .mat-datepicker-content {
      .mat-calendar {
        height: unset;

        .mat-calendar-controls {
          margin: 0;

          .mat-icon-button {
            color: mat.get-color-from-palette($primary);
          }
        }

        .mat-calendar-table-header-divider {
          display: none;
        }

        .mat-calendar-body {
          font-size: 1rem;

          .mat-calendar-body-cell-content {
            border-radius: 0.125rem;
            border: unset;
            height: 80%;
          }

          .mat-calendar-body-today:not(.mat-calendar-body-selected) {
            border-bottom: 0.125rem solid mat.get-color-from-palette($primary);
            color: mat.get-color-from-palette($primary);
          }
        }
      }
    }
  }
}
