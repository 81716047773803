@use '@angular/material' as mat;
@use 'sass:map';
@import 'variables/color';

@mixin erp-icon-color($theme) {
  $custom: map.get($theme, custom);

  .mat-icon {
    &.mat-light-gray {
      color: mat.get-color-from-palette($custom, light-gray);
    }

    &.mat-lime {
      color: mat.get-color-from-palette($custom, lime);
    }

    &.mat-dark-coral {
      color: $dark-coral;
    }

    &.mat-gray-button {
      color: $gray-button;
    }
  }

  .erp-tablet-module {
    .mat-icon {
      height: 2rem;
      width: 2rem;
    }
  }
}
