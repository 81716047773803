@use '@angular/material' as mat;
@use 'sass:map';
@import 'variables/color';

@mixin erp-table-custom-color($theme) {
  $primary: map.get($theme, primary);
  $custom: map.get($theme, custom);

  .erp-app {
    .erp-table-wrapper {
      overflow: auto;
    }

    .mat-table[border-collapse] {
      border-collapse: collapse;
    }

    .mat-header-row {
      &[color='primary'] {
        background-color: mat.get-color-from-palette($primary);
        color: mat.get-color-from-palette($primary, '500-contrast');
        height: 3rem;

        .mat-header-cell {
          color: unset;
          border: 1px solid mat.get-color-from-palette($primary, darker);
          white-space: nowrap;
        }
      }

      &[color='light-gray'] {
        background-color: mat.get-color-from-palette($custom, light-gray);
        color: mat.get-color-from-palette($custom, '500-contrast');
        height: 2rem;

        .mat-header-cell {
          color: unset;
          border: 1px solid mat.get-color-from-palette($custom, gray);
          white-space: nowrap;
        }
      }

      &[type='big'] {
        height: 4rem;

        .mat-header-cell {
          color: unset;
          white-space: normal;
        }
      }
    }

    .mat-cell {
      color: mat.get-color-from-palette($custom, light-graphite);
      border: 1px solid mat.get-color-from-palette($custom, light-gray);
    }

    .disabled-row {
      background-color: $whitesmoke;

      .mat-cell {
        color: $gray;
      }
    }

    .mat-footer-cell {
      border: none;
    }

    .mat-sort-header-arrow {
      color: mat.get-color-from-palette($primary, '500-contrast');
    }

    .mat-sort-header-container {
      .filter-icon {
        font-size: 1.2rem;
        margin-bottom: 0.25rem;
        margin-left: auto;
      }
    }

    .erp-table-nothing-found {
      color: mat.get-color-from-palette($custom, light-gray);
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
}
