@mixin erp-form-field-custom-color($theme) {
  $primary: map-get($theme, primary);

  .mat-form-field-outline {
    background-color: #fff;
  }

  .mat-form-field:not(.mat-form-field-disabled).mat-form-field-invalid {
    .mat-form-field-outline {
      background-color: $light-coral;
    }
  }

  .mat-form-field:not(.mat-form-field-invalid):not(.mat-form-field-disabled) {
    .mat-form-field-flex {
      &:hover,
      &:focus {
        .mat-form-field-outline {
          &-start,
          &-end {
            border-color: map-get($primary, 500);
          }
        }
      }
    }
  }
}
