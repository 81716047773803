$spacing-0: 0;
$spacing-1: 0.25rem;
$spacing-2: 0.5rem;
$spacing-3: 1rem;
$spacing-4: 1.5rem;
$spacing-5: 2.25rem;
$spacing-6: 3rem;
$spacing-7: 4.5rem;
$spacing-8: 6rem;
$spacing-9: 7.5rem;
$spacing-10: 10rem;
