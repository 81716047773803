@import 'shared/src/styles/index';

html,
body {
  min-height: 100vh;
  font-size: $font-size;
}

body {
  margin: 0;
  font-family: $font;
  overflow: auto;
}

* {
  outline: none;
}
