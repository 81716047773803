/* stylelint-disable no-descending-specificity */
erp-app {
  .mat-slide-toggle {
    height: 1rem;

    &-ripple {
      display: none;
    }

    &-label {
      height: inherit;
    }

    &-bar {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      height: inherit;
      width: 2rem;

      &::before,
      &::after {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        content: '';
        display: inline-block;
        height: 0.75rem;
        width: 0.75rem;
      }

      &::after {
        mask: url(/assets/icons/action/cross.svg);
        mask-size: contain;
        background-color: #fff;
        margin-right: 0.125rem;
      }
    }

    &-content {
      font-size: 1rem;
      font-weight: normal;
      margin-right: 0.5rem;
      overflow: visible;
    }

    &-thumb-container {
      top: 0;
      width: 1rem;
      height: 1rem;
      padding: 0.1rem 0.1rem 0 0;
    }

    &-thumb {
      box-shadow: unset;
      height: 0.8rem;
      width: 0.8rem;
      transform: translateX(0.15rem);
    }

    &.mat-checked {
      .mat-slide-toggle-label {
        .mat-slide-toggle-bar {
          justify-content: flex-start;

          &::before {
            mask: url(/assets/icons/action/check.svg);
            mask-size: contain;
            background-color: #fff;
            margin-left: 0.125rem;
          }

          &::after {
            display: none;
          }

          .mat-slide-toggle-thumb-container {
            .mat-slide-toggle-thumb {
              transform: translateX(0.0325rem);
            }
          }
        }
      }
    }
  }

  &.erp-tablet-module {
    .mat-slide-toggle {
      height: 1.66rem;

      &-bar {
        width: 3.375rem;
        border-radius: 1rem;

        &::before,
        &::after {
          height: 1.5rem;
          width: 1.5rem;
        }

        &::after {
          margin-right: 0.125rem;
        }
      }

      &-content {
        font-size: 1rem;
        margin-right: 0.5rem;
      }

      &-thumb-container {
        top: 0;
        width: 3.375rem;
        height: 1.66rem;
        padding: 0.1rem 0.1rem 0 0;
      }

      &-thumb {
        height: 1.3rem;
        width: 1.3rem;
        transform: translateX(0.15rem) translateY(0.075rem);
      }

      &.mat-checked {
        .mat-slide-toggle-label {
          .mat-slide-toggle-bar {
            &::before {
              margin-left: 0.125rem;
            }

            .mat-slide-toggle-thumb-container {
              .mat-slide-toggle-thumb {
                transform: translateX(0.9rem) translateY(0.075rem);
              }
            }
          }
        }
      }
    }
  }
}
