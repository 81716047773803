@use '@angular/material' as mat;
@use "sass:map";

@mixin erp-custom-color($theme) {
  $custom: map.get($theme, custom);

  .mat-transparent {
    background-color: mat.get-color-from-palette($custom, transparent);
    color: mat.get-color-from-palette(map.get($custom, contrast), transparent);
  }
}
