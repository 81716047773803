@import 'variables/spacing';

.erp-app {
  .container {
    padding: $spacing-4 $spacing-6 $spacing-6;

    &-sm {
      padding: $spacing-4;
    }
  }

  .erp-tablet-module,
  .erp-app-tablet {
    .container {
      padding: 1.5rem 3.5rem $spacing-4 2rem;
    }
  }
}
